/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

/* General Styles */
body,
html,
#root {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
}

/* Define heights for Navbar, Footer, and Main Content */
.navbar {
  flex-shrink: 0;
}

.main-content {
  flex: 1;
  overflow: auto;
}

.footer {
  height: 5vh;
  flex-shrink: 0;
}

/* Add this to your CSS file */
.confirmation-paragraph {
  text-align: center;
  justify-content: center;
  align-items: center;
  white-space: normal;
  word-wrap: break-word;
  margin: 1vw 5vw;
}

/* Other existing styles */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.device-photo {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.parent-container {
  display: flex;
  max-width: 100vw;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
  margin: 1vw auto;
  padding: 1vw;
}

.container {
  margin: 1vw auto;
  padding: 1vw;
  max-width: 100vw;
  background: white;
  box-shadow: 0 0 1vw rgba(0, 0, 0, 0.1);
  border-radius: 1vw;
  transition: all 0.5s ease-in-out;
}

/* Table Styles */
.markdown-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.markdown-table th,
.markdown-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.markdown-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.markdown-table th,
.markdown-table td {
  width: 50%;
}

/* Centering Images */
.markdown-table img {
  display: block;
  margin: 0 auto;
  width: 25%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Close Button Styles */
.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.custom-button {
  width: 90%;
  max-width: 350px;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-button:hover {
  background-color: #f9f9f9;
}

/* Footer Styles */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
}

.footer-left {
  font-size: 14px;
  color: #333;
}

.footer-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Media Queries */
@media (max-width: 700px) {
  .parent-container,
  .container {
    max-width: 100vw;
    display: block;
  }

  .navbar h1 {
    font-size: 18px;
  }

  .camera-upload,
  .confirmation {
    width: 100%;
  }

  .messages {
    max-height: 200px;
  }

  .skip-button {
    font-size: 6vw;
  }

  .camera-upload button,
  .confirmation button {
    width: 100%;
  }

  .navbar a {
    margin: 0 5px;
    font-size: 14px;
  }

  .navbar {
    padding: 10px 5px;
    height: 5vh;
  }

  .message .icon {
    width: 20px;
    height: 20px;
  }

  .message .message-text {
    max-width: 80%;
  }

  .input-container {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .input-container input {
    width: calc(100% - 70px);
    border: 0.5vw solid #ddd;
    border-radius: 5vw;
  }

  .input-container button {
    padding: 10px;
    border-radius: 2vw;
    color: white;
    cursor: pointer;
    margin-right: 3vw;
  }

  .device-info {
    margin-top: 2vw;
  }

  .device-info {
    padding: 0;
    margin: 0;
    border-radius: 0.5vw;
    font-size: 3vw;
  }
  .device-info .markdown-table {
    margin: -3vw;
  }
}
